<template>
  <div class="col-12 mt-3 pt-3 px-3">
    <div class="row m-0 justify-content-center">
      <div class="col-auto text-center px-0 pb-3 border_bottom mb-3">
        <div class="card_title">
          STEP {{activeStep}} of {{totalSteps}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepCount',
  props: ['activeComponent', 'wizardSteps'],
  computed: {
    activeStep () {
      if (this.activeComponent) {
        console.log('activeComponent', this.activeComponent)
        const theString = this.activeComponent.toLowerCase()
        const activeStep = this.wizardSteps.find(step => theString.includes(step.checkActiveString))

        return activeStep.stepNumber
      } else {
        return 0
      }
    },
    totalSteps () {
      if (this.wizardSteps && this.wizardSteps.length > 0) {
        return this.wizardSteps.length
      } else {
        return 0
      }
    }
  }
}
</script>

<style scoped>
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 0.9rem;
  }
}
</style>
